<template>
<div class="modal" v-if="confirmaVisible">
  <div class="modal-dialog modal-dialog-centered rounded">
    <div class="modal-content bg-dark text-white">
      <div class="modal-header">
        <h3 class="modal-title">{{this.confirmaMsj}}</h3>
        <button type="button" class="close text-white" @click="cancelar">
          <span>&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <button class="btn btn-danger btn-block" @click="aceptar"><h1>&nbsp;&nbsp;  Sí  &nbsp;&nbsp;</h1></button>
        <button class="btn btn-warning btn-block"  @click="cancelar"><h1>&nbsp;&nbsp; No &nbsp;&nbsp;</h1></button>
      </div>
    </div>
  </div>
</div>
<div class='container-fluid' :class="{ blur: borronear }">
  <br/>
  <br/>
  <br/>
  <div class='row'>
    <transition name="fade">
      <el-button v-show = "isLoggedIn && ficharVisible" size="large" type = "primary" @click="this.asistencia()" class="btn btn-primary mb-2">Iniciar Jornada</el-button>
    </transition>
  </div>
  <div class='row'>
    <transition name="fade">
      <el-button v-show = "isLoggedIn && finVisible" size="large" type = "primary" @click="this.finJornada()" class="btn btn-primary mb-2">Finalizar Jornada</el-button>
    </transition>
  </div>
  <div class='row'>
    <el-button v-show = "isLoggedIn && false" size="large" type = "primary" @click="xurl('/Itinerario')" class="btn btn-primary mb-2">Ver Cronograma</el-button>
  </div>
  <div class='row'>
    <el-button v-show = "isLoggedIn && false" size="large" type = "primary" @click="xurl('/Resumen')" class="btn btn-primary mb-2">Iniciar viaje</el-button>
  </div>
  <div class='row'>
    <el-button v-show = "isLoggedIn && false" size="large" type = "primary" class="btn btn-primary mb-2" @click="this.chkTecnico()">Checklist tecnico</el-button>
  </div>
  <div class='row'>
    <transition name="fade">
      <el-button v-show = "isLoggedIn && checklistVisible" size="large" type = "primary" class="btn btn-primary mb-2" @click="this.chkUnidad()">Checklist unidad</el-button>
    </transition>
  </div>
  <div class='row'>
    <el-button v-show = "!isLoggedIn" size="large" type = "primary" @click="xurl('/')" class="btn btn-primary mb-2">Login</el-button>
  </div>
  <br/>
  <br/>
  <br/>
<h1 v-if="msjSinViajes ===''">UUTT {{getUutt}}</h1>
<h1 v-if="msjSinViajes ===''">{{terminal}} {{hora}} hs</h1>
<h1 v-if="msjSinViajes!==''">{{msjSinViajes}}</h1>
</div>
</template>
<style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 18px;
}

.modal-content {
  padding: 20px;
  border: none;
  border-radius: 25;
  box-shadow: none;
}

.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding-bottom: 10px;
}

.modal-title {
  margin: 0;
}

.modal-body {
  padding-top: 10px;
  padding-bottom: 10px;
}

.close {
  outline: none;
  border: none;
  background-color: transparent;
  font-size: 1.5rem;
  cursor: pointer;
}

.btn {
  margin-right: 10px;
  height: 80px;
  border-radius: 18px;
}
.custom-margin {
  margin-left: 10px; /* Ajusta el valor según el espacio deseado */
}
img {
  display: relative;
  max-width: 90%;
  height: auto;
}
.el-button {
  font-size: 36px !important;
}
/* Duración de las transiciones */
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
/* Estado inicial para el fade-in */
.fade-enter, .fade-enter-from {
  opacity: 0;
}
/* Estado final para el fade-in */
.fade-enter-to {
  opacity: 1;
}
/* Estado inicial y final para el fade-out */
.fade-leave, .fade-leave-to {
  opacity: 1;
}
/* Estado intermedio para el fade-out */
.fade-leave-active {
  opacity: 0;
}
.blur {
  filter: blur(2px);
}
</style>
<script>
import config from '../../public/config.json'
import axios from 'axios'
import { mapGetters, mapMutations } from 'vuex'
import shared from '../shared/shared'
export default {
  extends: shared,
  name: 'Home',
  computed: {
    ...mapGetters(['isLoggedIn']),
    ...mapGetters(['getToken']),
    ...mapGetters(['getArea']),
    ...mapGetters(['getDni']),
    ...mapGetters(['getUutt'])
  },
  components: {
    // back
  },
  mounted () {
    this.confirmaVisible = false
    this.borronear = false
    this.commonLogic()
  },
  data () {
    return {
      resultAsistencia: null,
      resultChk: null,
      ficharVisible: false,
      finVisible: false,
      checklistVisible: false,
      confirmaOpcion: '',
      confirmaMsj: '',
      confirmaVisible: false,
      borronear: false,
      terminal: '',
      hora: '',
      msjSinViajes: '',
      resxcat: [],
      chartState: {
        chartData: {
          datasets: [
            {
              data: [],
              backgroundColor: []
            }
          ],
          labels: []
        },
        chartOptions: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              position: 'top'
            },
            title: {
              display: true,
              text: 'Chart.js Doughnut Chart'
            }
          }
        }
      }
    }
  },
  created () {
    document.title = 'ChoferesApp'
  },
  methods: {
    async commonLogic () {
      this.msjSinViajes = ''
      const self = this
      let viajesPendientes = false
      let presente = false
      let qr = false
      if (this.isLoggedIn) {
        // consigue viajes pendientes
        let url = 'http://' + config.ip + '/comcam/choferesapp/pendientes/dni/params;dni=' + self.getDni
        try {
          const response = await self.makeGetRequest(url, 0)
          self.resumen = JSON.parse(JSON.stringify(response).split('T00:00:00Z[-03:00]').join(''))
          if (Array.isArray(self.resumen) && self.resumen.length > 0) {
            viajesPendientes = true
            self.setUutt(self.resumen[0].CODUNI)
            self.terminal = self.resumen[0].Terminal
            self.hora = self.resumen[0].HoraCitacion
          }
        } catch (error) {
          console.log('rtx:' + error)
        }
        url = 'http://' + config.ip + '/comcam/choferesapp/asistencia/dni/params;dni=' + self.getDni
        // consigue presente
        await self.makeGetRequest(url, 0)
          .then(response => {
            this.resultAsistencia = JSON.parse(JSON.stringify(response).split('T00:00:00Z[-03:00]').join(''))
            presente = this.resultAsistencia === 'true'
          })
          .catch(error => {
            console.log(error)
          })
        // consigue estado qr
        url = 'http://' + config.ip + '/comcam/choferesapp/chklist/status/params;uutt=' + self.getUutt + ';dni=' + self.getDni
        await self.makeGetRequest(url)
          .then(response => {
            this.resultChk = JSON.parse(JSON.stringify(response).split('T00:00:00Z[-03:00]').join(''))
            qr = this.resultChk.STATUS.toLowerCase() === 'ok'
          })
          .catch(error => {
            console.log(error)
          })
        if (viajesPendientes && presente && qr) {
          try {
            if (Array.isArray(self.resumen) && self.resumen.length > 0) {
              self.navigateToDestinos(self.resumen[0].TSTORI, self.resumen[0].CODUNI, self.resumen[0]) // si tiene viajes, muestra proximo pasos
            } else {
              this.finVisible = true // pude finalizar jornada
            }
          } catch (error) {
            console.log(error)
          }
        } else if (viajesPendientes && !presente) {
          this.ficharVisible = true
          this.checklistVisible = false
        } else if (viajesPendientes && presente && !qr) {
          this.ficharVisible = false
          this.checklistVisible = true
        } else if (!viajesPendientes) {
          this.msjSinViajes = 'Por el momento, todos los viajes estan cumplidos. Gracias por utilizar APP choferes COMCAM'
        }
      }
    },
    async navigateToDestinos (tstori, unidad, item) {
      try {
        /* eslint-disable no-undef */
        AndroidInterface.evento('lugar:' + item.Terminal + ';unidad:' + unidad + ';tstamp:' + tstori)
        /* eslint-disable no-undef */
      } catch (error) {
        console.log('rtx: ' + error)
      }
      this.$router.push('/Destinos/?tstori=' + tstori + '&unidad=' + unidad)
    },
    asistencia () {
      this.confirmaVisible = true
      this.confirmaMsj = '¿Registrar inicio jornada?'
      this.confirmaOpcion = 'inicio'
      this.borronear = true
      // this.commonLogic()
    },
    finJornada () {
      this.confirmaMsj = 'registrar fin jornada ?'
      this.confirmaVisible = true
      this.confirmaOpcion = 'fin'
      // this.commonLogic()
    },
    aceptar () {
      this.confirmaVisible = false
      this.borronear = false
      if (this.confirmaOpcion.toLowerCase() === 'inicio') {
        try {
          /* eslint-disable no-undef */
          AndroidInterface.evento('inicio jornada')
          /* eslint-disable no-undef */
        } catch (error) {
          console.log('desde navegador')
        }
      }
      if (this.confirmaOpcion.toLowerCase() === 'fin') {
        try {
          /* eslint-disable no-undef */
          AndroidInterface.evento('fin jornada')
          /* eslint-disable no-undef */
        } catch (error) {
          console.log('desde navegador')
        }
      }
      setTimeout(() => {
        this.commonLogic()
      }, 2000) // Esperar un toque antes de redireccionar
    },
    cancelar () {
      this.confirmaVisible = false
      this.borronear = false
      this.commonLogic()
    },
    makeGetRequest (url, retryCount = 0) {
      const self = this
      return new Promise((resolve, reject) => {
        axios.get(url)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            if (retryCount < 3) {
              // Incrementar el contador de reintento
              const newRetryCount = retryCount + 1
              console.log(`Error en GET, reintentando (${newRetryCount})...`)
              // Esperar un breve período de tiempo antes de hacer el próximo intento
              setTimeout(() => {
                self.makeGetRequest(url, newRetryCount)
                  .then(response => {
                    resolve(response)
                  })
                  .catch(error => {
                    reject(error)
                  })
              }, 500) // Esperar 1 segundo antes de cada reintento
            } else {
              reject(error)
            }
          })
      })
    },
    chkUnidad () {
      try {
        /* eslint-disable no-undef */
        AndroidInterface.qr('tomaunidad')
        AndroidInterface.evento('se lanzo qr toma unidad')
        /* eslint-disable no-undef */
      } catch (error) {
        console.log('rtx: ' + error)
      }
    },
    chkTecnico () {
      try {
        /* eslint-disable no-undef */
        AndroidInterface.qr('tecnico')
        AndroidInterface.evento('se lanzo qr tecnico')
        /* eslint-disable no-undef */
      } catch (error) {
        console.log('rtx: ' + error)
      }
    },
    ...mapMutations(['setUser', 'setToken', 'setArea', 'setDni', 'setUutt']),
    salir () {
      this.setUser(null)
      this.setArea(null)
      this.setToken(null)
      this.setDni(null)
      this.setUutt(null)
      this.$router.push('/')
    },
    xurl (param) {
      try {
        /* eslint-disable no-undef */
        AndroidInterface.evento('se lanzo' + param.replace('/', ''))
        /* eslint-disable no-undef */
      } catch (error) {
        console.log('rtx:' + 'no se detecto interface con android, probablemente se lanzo desde la web')
      }
      this.$router.push(param)
    }
  }
}
</script>
