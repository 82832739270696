import axios from 'axios'
import config from '../../public/config.json'
import { ElNotification } from 'element-plus'
export default {
  data: function () {
    return {
      impIndex: 0
    }
  },
  methods: {
    async persist (pUrl, pMethod, pBody) {
      return new Promise((resolve, reject) => {
        fetch(pUrl, {
          method: pMethod,
          dataType: 'json',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: this.getToken.token
          },
          body: pBody
        })
          .then(async response => {
            if (response.status === 401) {
              this.errorMsg('No cuenta con permisos para continuar la operacion')
            } else {
              const jsonResponse = await response.json()
              localStorage.setItem('newticket', JSON.stringify(jsonResponse).split('T00:00:00Z[-03:00]').join(''))
              this.successMsg('Operacion exitosa')
              resolve(jsonResponse)
            }
          })
          .catch(error => {
            console.error('Error al grabar: ', error)
            this.errorMsg('Error al grabar')
            reject(error)
          })
      })
    },
    getSubCategorias (self, categoria) {
      // var self = this
      // self.openFSL()
      const url = 'http://' + config.ip + '/ticket/usrsub/childs/params;categoria=' + categoria
      return axios.get(url, {
        dataType: 'json',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: self.getToken.token
        },
        mode: 'no-cors',
        credentials: 'include'
      })
        .then(function (response) {
          localStorage.setItem('subCategorias', JSON.stringify(response.data).split('T00:00:00Z[-03:00]').join(''))
          // self.empresas = JSON.parse(localStorage.getItem('empresas'))
          // self.closeFSL()
        })
        .catch(function (error) {
          console.log(error)
          // self.closeFSL()
        })
    },
    getCategorias (self) {
      // var self = this
      // self.openFSL()
      const url = 'http://' + config.ip + '/ticket/usrcat;'
      return axios.get(url, {
        dataType: 'json',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: self.getToken.token
        },
        mode: 'no-cors',
        credentials: 'include'
      })
        .then(function (response) {
          localStorage.setItem('categorias', JSON.stringify(response.data).split('T00:00:00Z[-03:00]').join(''))
          // self.empresas = JSON.parse(localStorage.getItem('empresas'))
          // self.closeFSL()
        })
        .catch(function (error) {
          console.log(error)
          // self.closeFSL()
        })
    },
    getEmpresas (self) {
      // var self = this
      // self.openFSL()
      const url = 'http://' + config.ip + '/ticket/empresa;'
      return axios.get(url, {
        dataType: 'json',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: self.getToken.token
        },
        mode: 'no-cors',
        credentials: 'include'
      })
        .then(function (response) {
          localStorage.setItem('empresas', JSON.stringify(response.data).split('T00:00:00Z[-03:00]').join(''))
          // self.empresas = JSON.parse(localStorage.getItem('empresas'))
          // self.closeFSL()
        })
        .catch(function (error) {
          console.log(error)
          // self.closeFSL()
        })
    },
    _additemTareas (self) {
      // obtiene el nro de item maximo
      var _nroItem = 0
      self.ticket.fases.forEach(function (x) {
        if (_nroItem < x.usrFasesPK.usrFasesNroitem) {
          _nroItem = x.usrFasesPK.usrFasesNroitem
        }
      }
      )
      var obj =
        {
          usrFasesPK: { usrFasesSttickNroticket: self.ticket.usrSttickNroticket, usrFasesNroitem: _nroItem + 1 },
          usrFasesCodfase: null,
          usrFasesFechainicio: null,
          usrFasesFechafin: null,
          usrFasesProf: '',
          usrFasesObserva: '',
          usrFasesTareas: '',
          usrFasesVercon1: null,
          usrFasesVercon2: null,
          usrFaFecalt: null,
          usrFaFecmod: null,
          usrFaUserid: 'ADMIN',
          usrFaUltopr: 'A',
          usrFaDebaja: 'N',
          usrFaHormov: null,
          usrFaModule: null,
          usrFaOalias: 'USR_FASES',
          usrFaLottra: null,
          usrFaLotrec: null,
          usrFaLotori: null,
          usrFaSysver: null,
          usrFaCmpver: null,
          usrFasesHs: 0
        }
      self.ticket.fases.push(obj)
    },
    getProfesionals (self) {
      // self.openFSL(self)
      const url = 'http://' + config.ip + '/ticket/profesional/'
      return axios.get(url, {
        dataType: 'json',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: self.getToken.token
        },
        mode: 'no-cors',
        credentials: 'include'
      })
        .then(function (response) {
          localStorage.setItem('asignados', JSON.stringify(response.data).split('T00:00:00Z[-03:00]').join(''))
          self.asignados = JSON.parse(localStorage.getItem('asignados'))
          // self.closeFSL(self)
        })
        .catch(function (error) {
          console.log(error)
          // self.closeFSL(self)
        })
    },
    getUsers (self) {
      // var self = this
      // self.openFSL()
      const url = 'http://' + config.ip + '/ticket/protick/'
      return axios.get(url, {
        dataType: 'json',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: self.getToken.token
        },
        mode: 'no-cors',
        credentials: 'include'
      })
        .then(function (response) {
          localStorage.setItem('usuarios', JSON.stringify(response.data).split('T00:00:00Z[-03:00]').join(''))
          // self.usuarios = JSON.parse(localStorage.getItem('usuarios'))
          // self.closeFSL()
        })
        .catch(function (error) {
          console.log(error)
          // self.closeFSL()
        })
    },
    getDepartamentos (self) {
      // var self = this
      // self.openFSL()
      const url = 'http://' + config.ip + '/ticket/departamento/params;'
      return axios.get(url, {
        dataType: 'json',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: self.getToken.token
        },
        mode: 'no-cors',
        credentials: 'include'
      })
        .then(function (response) {
          localStorage.setItem('departamentos', JSON.stringify(response.data).split('T00:00:00Z[-03:00]').join(''))
          // self.departamentos = JSON.parse(localStorage.getItem('departamentos'))
          // self.departamento = self.getArea.area
          // self.closeFSL()
        })
        .catch(function (error) {
          console.log(error)
          // self.closeFSL()
        })
    },
    isNumber (evt) {
      evt = evt || window.event
      var charCode = (evt.which) ? evt.which : evt.keyCode
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    openFSL (self) {
      self.loading = self.$loading({
        lock: true,
        text: 'Cargando',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
    },
    closeFSL (self) {
      self.loading.close()
    },
    bar () {
      alert('bar')
    },
    successMsg (val) {
      ElNotification({
        title: 'Info',
        message: val,
        type: 'success'
      })
      // this.$message({
      //   message: val,
      //   type: 'success'
      // })
    },
    errorMsg (val) {
      this.$message({
        message: 'mensaje de error: ' + val,
        type: 'error'
      })
    },
    mostrarImputacion (self, fecha, profesional, texto) {
      self.dialogVisible = true
      self.dialogo = 'FECHA:\n' + fecha + '\n\n'
      self.dialogo = self.dialogo + 'PROFESIONAL:\n' + profesional + '\n\n'
      self.dialogo = self.dialogo + 'TEXTO:\n' + texto
    },
    mostrarImputacionV2 (self, item, index) {
      this.impIndex = index
      self.dialogVisible = true
      self.dialogo = 'TICKET:\n' + item[index].ticket + ' - ' + item[index].titulo + '\n\n'
      self.dialogo = self.dialogo + 'FECHA:\n' + item[index].fecha + '\n\n'
      self.dialogo = self.dialogo + 'PROFESIONAL:\n' + item[index].profesional + '\n\n'
      self.dialogo = self.dialogo + 'HS:\n' + item[index].horas + '\n\n'
      self.dialogo = self.dialogo + 'TEXTO:\n' + item[index].texto
    },
    numberFormat (val, digit) {
      const formatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: digit,
        maximumFractionDigits: digit
      })
      return formatter.format(val)
    }
  }
}
