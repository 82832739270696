<template>
  <!-- Verifica el estado de la lista de verificación -->
  <span v-if='chkok.STATUS && chkok.STATUS!=="OK"'>
    <br/>
    <h1> Por favor realice checklist de toma de unidad </h1>
  </span>
  <!-- Verifica el estado de la lista de verificación y el estado del diálogo -->
  <span v-if='!showDialog && chkok.STATUS==="OK"'>
    <!-- Itera a través de los destinos -->
    <div v-if="destinos" :class="{ blur: borronear }">
      <br>
      <el-divider></el-divider>
      <h3>{{ destinos[0].TITULO }}</h3>
      <div v-for="destino in destinos" :key="destino.id" class="row" >
        <h3 v-if="debug" >destino.NROCTA:{{ destino.NROCTA }}, destino.BOTON: {{ destino.BOTON }}, destino.DESTINO: {{ destino.DESTINO }} Titulo: {{ destino.TITULO }}</h3>
        <!-- Verifica si se rechazó un producto y si el destino incluye [FIN DE JORNADA] -->
       <el-button
        v-if="destino.DESTINO.includes('FIN DE JORNADA')"
              size="large"
              type="success"
              @click="marcarEvento(destino.NROCTA, destino.BOTON, destino.DESTINO)"
              plain
              style="border-radius: 18px; margin-top: 15px;"
              class="wrap-button-text centered-text"
            >
              Finalizar Jornada <br>
              (llegue a guarda)
            </el-button>
        <div class="col-md-1" v-if="!destino.DESTINO.includes('FIN DE JORNADA')">
          <div class="col-md-1" v-if="!rechazoProducto ">
            <el-button-group>
              <!-- Verifica si el botón es FIN -->
              <div v-if="destino.BOTON === 'FIN' " >
                <br>
                <h1>¿La descarga fue exitosa?</h1>
                <br>
                <!-- Botón de descarga exitosa -->
                <el-button
                  type="success"
                  @click="marcarEvento(destino.NROCTA, destino.BOTON, destino.DESTINO)"
                  plain
                  style="border-radius: 18px;"
                  class="wrap-button-text centered-text"
                >
                  Si, descarga exitosa
                </el-button>
                <!-- Botón de rechazo de producto -->
                <el-button
                  type="danger"
                  @click="rechazoProducto = true"
                  plain
                  style="border-radius: 18px; margin-top: 15px;"
                  class="wrap-button-text centered-text"
                >
                  No, se rechazó un producto
                </el-button>
              </div>
              <!-- Si el botón no es FIN -->
              <el-button
                v-else
                size="large"
                type="success"
                @click="marcarEvento(destino.NROCTA, destino.BOTON, destino.DESTINO)"
                class="wrap-button-text centered-text"
                style="border-radius: 18px; margin-top: 15px; "
              >
              <div class="col-md-1 evento" :class="{'amarillo': destino.DESTINO.includes('[D]')}" > {{ destino.DESTINO }}</div>
              </el-button>
            </el-button-group>
          </div>
        <!-- Si se rechazó un producto -->
        <div v-else>
          <br><br>
          <h3>¿Cuál producto fue rechazado?</h3>
          <!-- Itera a través de los productos -->
          <div v-for="producto in productos" :key="producto.id" class="row">
            <!-- Botón de producto -->
            <el-button
              size="large"
              type="primary"
              @click="marcarRechazo(producto)"
              plain
              style="border-radius: 18px; margin-top: 15px; border-radius: 90px; margin-left: 25px; margin-right: 20px; width: 90%;"
            >
              {{ producto.Producto }}
            </el-button>
          </div>
          <!-- Botón de continuar sin rechazo -->
          <el-button
              size="large"
              type="success"
              @click="marcarEvento(destino.NROCTA, destino.BOTON, destino.DESTINO)"
              plain
              style="border-radius: 18px; margin-top: 15px;"
              class="wrap-button-text centered-text"
            >
            Continuar sin rechazo
          </el-button>
        </div>
      </div>
      </div>
      <el-divider></el-divider>
      <!-- Diálogo de confirmación para llamar a logística
      <el-dialog
        title="Confirmar"
        :model-value="mostrarDialogo"
        @update:model-value="mostrarDialogo = $event"
      >
        <span>¿Desea llamar a logística?</span>
        <template v-slot:footer>
          <el-button @click="mostrarDialogo = false">No</el-button>
          <el-button type="primary" @click="llamar(); mostrarDialogo = false">Sí</el-button>
        </template>
      </el-dialog>-->
    </div>
  </span>
</template>

<script>
import { mapGetters } from 'vuex'
import config from '../../public/config.json'
import axios from 'axios'
import shared from '../shared/shared'
import { ElMessageBox } from 'element-plus'

export default {
  extends: shared,
  name: 'Home',
  props: {
    tstori: String,
    unidad: String
  },
  computed: {
    ...mapGetters(['isLoggedIn']),
    ...mapGetters(['getToken']),
    ...mapGetters(['getDni'])
  },
  data () {
    return {
      chkok: '',
      rechazo: {
        artcod: '',
        tippro: '',
        producto: '',
        cantid: 0,
        rechazado: null
      },
      showDialog: false,
      destinos: null,
      productos: null,
      editednotas: {},
      textoVisible: false,
      asignados: [],
      profesional: '',
      rechazoProducto: false,
      mostrarDialogo: false,
      debug: false,
      borronear: false,
      llamarAlogistica: false
    }
  },
  mounted () {
    this.init()
  },
  beforeMount () {
    document.title = 'Destinos'
  },
  methods: {
    // Método para hacer una solicitud GET con reintentos
    makeGetRequest (url, retryCount = 0) {
      const self = this
      return new Promise((resolve, reject) => {
        axios.get(url)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            if (retryCount < 3) {
              // Incrementa el contador de reintento
              const newRetryCount = retryCount + 1
              console.log(`Error en GET, reintentando (${newRetryCount})...`)
              // Espera un breve período de tiempo antes del próximo intento
              setTimeout(() => {
                self.makeGetRequest(url, newRetryCount)
                  .then(response => {
                    resolve(response)
                  })
                  .catch(error => {
                    reject(error)
                  })
              }, 500) // Espera 1 segundo antes de cada reintento
            } else {
              reject(error)
            }
          })
      })
    },
    // Método para inicializar el componente
    async init () {
      console.log('init')
      this.productos = null
      var self = this
      if (this.isLoggedIn) {
        let url = 'http://' + config.ip + '/comcam/choferesapp/destinos/tstori/params;tstori=' + self.$route.query.tstori
        self.makeGetRequest(url)
          .then(response => {
            console.log('makeGetRequest destinos ok')
            self.destinos = JSON.parse(JSON.stringify(response).split('T00:00:00Z[-03:00]').join(''))
            if (self.destinos.length > 0) {
              if (self.destinos[0].BOTON === 'FIN') {
                self.productosx(self.destinos[0].NROCTA)
              }
            } else {
              self.$router.go(-1)
            }
          })
          .catch(error => {
            console.log(error)
          })
        url = 'http://' + config.ip + '/comcam/choferesapp/chklist/status/params;uutt=' + self.$route.query.unidad + ';dni=' + self.getDni
        self.makeGetRequest(url)
          .then(response => {
            console.log('makeGetRequest chkok ok')
            self.chkok = JSON.parse(JSON.stringify(response).split('T00:00:00Z[-03:00]').join(''))
            if (this.llamarAlogistica) {
              self.llamaraLogistica()
            }
          })
          .catch(error => {
            console.log(error)
          })
      }
    },
    // Método para marcar un rechazo
    rechazar () {
      const self = this
      this.borronear = true
      ElMessageBox.confirm(
        '¿Confirma RECHAZO?',
        '',
        {
          cancelButtonText: 'No',
          confirmButtonText: 'Si',
          type: 'warning',
          customClass: 'rechazo',
          roundButton: true
        }
      )
        .then(() => {
          const url = 'http://' + config.ip + '/comcam/choferesapp/destinos/rechazo/params;tst=' + self.$route.query.tstori + ';artcod=' + self.rechazo.artcod + ';tippro=' + self.rechazo.tippro + ';nrocta=' + self.destinos[0].NROCTA + ';cantid=0'
          self.openFSL(self)
          fetch(url, {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: self.getToken.token
            },
            body: ''
          })
            .then(async response => {
              if (response.status === 401) {
                self.errorMsg('permisos insuficientes para completar la operacion')
              } else {
                self.showDialog = false
                self.borronear = false
                self.marcarEvento(self.destinos[0].NROCTA, self.destinos[0].BOTON, self.destinos[0].DESTINO)
                self.llamarAlogistica = true
                self.rechazoProducto = false
              }
              self.closeFSL(self)
            })
            .catch(error => {
              console.error('Error al grabar: ', error)
              self.errorMsg('Error al grabar')
              self.rechazoProducto = false
              self.init()
              self.closeFSL(self)
            })
        })
        .catch(() => {
          self.showDialog = false
          self.borronear = false
        })
    },
    llamaraLogistica () {
      const self = this
      self.llamarAlogistica = false
      // this.borronear = true
      ElMessageBox.confirm(
        '¿Desea llamar a logistica?',
        '',
        {
          cancelButtonText: 'No',
          confirmButtonText: 'Si',
          type: 'warning',
          customClass: 'rechazo',
          roundButton: true
        }
      )
        .then(() => {
          self.llamar()
          self.showDialog = false
          // self.mostrarDialogo = true
          self.borronear = false
        })
        .catch(() => {
          self.showDialog = false
          self.borronear = false
          self.init()
        })
    },
    // Método para marcar un rechazo de producto
    marcarRechazo (producto) {
      console.log('marca rechazo')
      this.rechazo.artcod = producto.ARTCOD
      this.rechazo.tippro = producto.TIPPRO
      this.rechazo.producto = producto.Producto
      this.rechazo.cantid = 0
      // this.mostrarDialogo = true
      this.rechazar()
    },
    // Método para marcar un evento
    marcarEvento (nrocta, boton, destino) {
      const self = this
      const url = 'http://' + config.ip + '/comcam/choferesapp/destinos/llegueterminal/params;tst=' + self.$route.query.tstori + ';tipoevento=' + boton + ';nrocta=' + nrocta
      console.log('Metodo > marcarEvento: ' + url)
      this.openFSL(this)
      fetch(url, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: this.getToken.token
        },
        body: ''
      })
        .then(async response => {
          if (response.status === 401) {
            this.errorMsg('permisos insuficientes para completar la operacion')
          } else {
            this.init()
            try {
              /* eslint-disable no-undef */
              AndroidInterface.evento('destino:' + destino + '|opcion:' + boton + '|tstamp:' + self.$route.query.tstori + '|nrocta:' + nrocta)
              /* eslint-disable no-undef */
            } catch (error) {
              console.log('rtx: ' + error)
            }
          }
          this.closeFSL(this)
        })
        .catch(error => {
          console.error('Error al grabar: ', error)
          this.errorMsg('Error al grabar')
          this.closeFSL(this)
        })
    },
    // Método para llamar a logística
    llamar () {
      try {
        /* eslint-disable no-undef */
        AndroidInterface.hacerLlamada('1121591702')
        /* eslint-disable no-undef */
      } catch (error) {
        console.log('error llamada: ' + error)
      }
    },
    // Método para obtener los productos por nrocta
    productosx (nrocta) {
      var self = this
      self.openFSL(self)
      self.closeFSL(self)
      self.destino = self.$route.query.descripcion
      if (this.isLoggedIn) {
        const url = 'http://' + config.ip + '/comcam/choferesapp/productos/params;tstori=' + self.$route.query.tstori + ';nrocta=' + nrocta
        axios.get(url, {
          dataType: 'json',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: self.getToken.token
          },
          mode: 'no-cors',
          credentials: 'include'
        })
          .then(function (response) {
            self.productos = JSON.parse(JSON.stringify(response.data).split('T00:00:00Z[-03:00]').join(''))
            self.closeFSL(self)
          })
          .catch(function (error) {
            console.log(error)
            self.closeFSL(self)
          })
      }
    }
  }
}
</script>

<style>
img {
  display: relative;
  max-width: 90%;
  height: auto;
}
.evento{
  font-size: 36px !important;
}
.amarillo{
  color: yellow !important;
}
.el-button {
  font-size: 36px !important;
}
.el-message-box__content{
 font-size: 24px !important;
}
.el-message-box__btns{
  text-align: center !important;
  margin-top: 15px;
  margin-bottom: 15px;
}
.el-message-box__btns button:first-child{
  text-align: center !important;
  background-color: rgb(55, 83, 55);
  color: white;
  padding: 20px 50px;
  margin-left: 10px;
  margin-right: 10px;
}
.el-message-box__btns button:last-child{
  text-align: center !important;
  background-color: rgb(255, 0, 0);
  color: white;
  padding: 20px 50px;
  margin-left: 10px;
  margin-right: 10px;
}
/* Duración de las transiciones */
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
/* Estado inicial para el fade-in */
.fade-enter, .fade-enter-from {
  opacity: 0;
}
/* Estado final para el fade-in */
.fade-enter-to {
  opacity: 1;
}
/* Estado inicial y final para el fade-out */
.fade-leave, .fade-leave-to {
  opacity: 1;
}
/* Estado intermedio para el fade-out */
.fade-leave-active {
  opacity: 0;
}
.wrap-button-text {
  white-space: normal; /* Permite que el texto se envuelva */
  word-wrap: break-word; /* Rompe la palabra si es demasiado larga para ajustarse */
  width: 100%; /* Asegura que el botón no se desborde */
  text-align: left; /* Alinea el texto a la izquierda. Puedes ajustar esto según tus necesidades */
}
.centered-text {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.blur {
  filter: blur(2px);
}
</style>
