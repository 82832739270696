<template>
<div>
  <Itinerario :dni='this.getDni' />
</div>
</template>
<style scoped>
img {
  display: relative;
  max-width: 90%;
  height: auto;
}
.el-button {
  font-size: 36px !important;
  border-radius: 42px;
}
</style>
<script>
import Itinerario from '../components/Itinerario.vue'

import { mapGetters } from 'vuex'
export default {
  components: {
    Itinerario
  },
  computed: {
    ...mapGetters(['getDni'])
  }

}
</script>
