<template>
    <div class="row">
      <div class="col-md-6">
        <!--<el-button type = "primary" @click="back()"><el-icon :size="40"><ArrowLeftBold /></el-icon></el-button>-->
        <!--<el-button type = "primary" @click="this.$router.push('/Home')" class="mr-2"><el-icon size="40"><HomeFilled /></el-icon></el-button>-->
        <el-button type = "success" @click="llamar"><el-icon :size="40"><Phone /></el-icon></el-button>
        <el-button type = "danger" @click="mostrarModal"><el-icon :size="40"><Bell /></el-icon></el-button>
      </div>
    </div>
    <div class="modal" v-if="mostrarConfirmacion">
      <div class="modal-dialog modal-dialog-centered rounded">
        <div class="modal-content bg-dark text-white">
          <div class="modal-header">
            <h3 class="modal-title">¿Está seguro que desea enviar el S.O.S.?</h3>
            <button type="button" class="close text-white" @click="cancelarSOS">
              <span>&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <h3><p>Chofer: {{ chofer }}</p></h3>
            <h3><p>Unidad: {{ uutt }}</p></h3>
            <button class="btn btn-danger btn-block" @click="enviarSOS"><h1>&nbsp;&nbsp;Sí&nbsp;&nbsp;</h1></button>
            <button class="btn btn-warning btn-block" @click="cancelarSOS"><h1>&nbsp;&nbsp;No&nbsp;&nbsp;</h1></button>
          </div>
        </div>
      </div>
    <div class="modal" v-if="mostrarMensaje">
      <div class="modal-dialog modal-dialog-centered rounded">
        <div class="modal-content bg-dark text-white">
          <div class="modal-header">
            <h5 class="modal-title">Mensaje</h5>
            <button type="button" class="close text-white" @click="cerrarMensaje">
              <span>&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>{{ mensaje }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
  import config from '../../public/config.json'
  import router from '../router'
  import axios from 'axios'
  import back from './back.vue'
  export default {
    components: {
    back
  },
  props: {
    chofer: {
      type: String,
      required: true
    },
    unidad: {
      type: String,
      required: true
    }
  },
    data() {
      return {
        uutt:'',
        resumen: [],
        mostrarConfirmacion: false,
        mostrarMensaje: false,
        mensaje: "",
      };
    },
    methods: {
      llamar () {
        /* eslint-disable no-undef */
        AndroidInterface.hacerLlamada('1121591702')
        /* eslint-disable no-undef */
      },
      makeGetRequest (url, retryCount = 0) {
      const self = this
      return new Promise((resolve, reject) => {
        axios.get(url)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            if (retryCount < 3) {
              // Incrementar el contador de reintento
              const newRetryCount = retryCount + 1
              console.log(`Error en GET, reintentando (${newRetryCount})...`)
              // Esperar un breve período de tiempo antes de hacer el próximo intento
              setTimeout(() => {
                self.makeGetRequest(url, newRetryCount)
                  .then(response => {
                    resolve(response)
                  })
                  .catch(error => {
                    reject(error)
                  })
              }, 500) // Esperar 1 segundo antes de cada reintento
            } else {
              reject(error)
            }
          })
      })
    },
      back () {
        router.go(-1)
      },
      forward () {
        router.go(1)
      },
      reloadPage () {
        window.location.reload()
      },
      mostrarModal() {
        this.recopilardatos()
        this.mostrarConfirmacion = true;
      },
      async recopilardatos () {
        var self = this
        const url = 'http://' + config.ip + '/comcam/choferesapp/pendientes/dni/params;dni=' + this.chofer
        self.makeGetRequest(url, 0)
          .then(response => {
            self.resumen = JSON.parse(JSON.stringify(response).split('T00:00:00Z[-03:00]').join(''))
            // self.$emit('update:unidad', 20)
            self.uutt = self.resumen[0].CODUNI
            console.log('rtx:' + self.resumen[0].CODUNI)
            try {
              /* eslint-disable no-undef */
              AndroidInterface.evento('intento S.O.S')
              /* eslint-disable no-undef */
            } catch (error) {
              console.log('rtx:' + error)
            }
          })
          .catch(error => {
            console.log(error)
          })
      },
      cancelarSOS() {
        this.mostrarConfirmacion = false;
      },

      cerrarMensaje() {
        this.mostrarMensaje = false;
      },
      async enviarSOS () {
      // var self = this
      // const url = 'http://' + config.ip + '/comcam/choferesapp/pendientes/dni/params;dni=' + this.chofer
      // await self.makeGetRequest(url, 0)
      //   .then(response => {
      //     self.resumen = JSON.parse(JSON.stringify(response).split('T00:00:00Z[-03:00]').join(''))
      //     console.log('rtx:' + JSON.parse(JSON.stringify(response).split('T00:00:00Z[-03:00]').join('')))
      //   })
      //   .catch(error => {
      //     console.log(error)
      //   })
      //   let unidad = ''
      //   try{
      //     unidad = self.resumen[0].CODUNI
      //     } catch(error) {
      //       console.log(error)
      //     }
      fetch(
        'http://' + config.ip + '/logistica/INS_SOS/params;chofer=' + this.chofer + 
        ';uutt=' + this.uutt
        , {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: self.token
          },
          body: JSON.stringify({
          })
        })
        .then(async response => {
          const rtas = await response.json()
          console.log(rtas)
          // Llamar al método para enviar el S.O.S.
            this.mostrarConfirmacion = false;
            this.mensaje = "Se envió exitosamente";
            try {
              /* eslint-disable no-undef */
              AndroidInterface.evento('confirmacion S.O.S')
              /* eslint-disable no-undef */
            } catch (error) {
              console.log('rtx:' + error)
            }
            this.mostrarMensaje = true;
            setTimeout(() => {
            this.mostrarMensaje = false;
            }, 2000);
        })
        .catch(error => {
          console.log('error:enviarSOS')  
          console.log(error);
        })
    }
    },
  };
  </script>
  
  <style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  padding: 20px;
  border: none;
  border-radius: 25;
  box-shadow: none;
}

.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding-bottom: 10px;
}

.modal-title {
  margin: 0;
}

.modal-body {
  padding-top: 10px;
  padding-bottom: 10px;
}

.close {
  outline: none;
  border: none;
  background-color: transparent;
  font-size: 1.5rem;
  cursor: pointer;
}

.btn {
  margin-right: 10px;
  height: 80px;
  border-radius: 10px;
}
.custom-margin {
  margin-left: 10px; /* Ajusta el valor según el espacio deseado */
}
</style>
